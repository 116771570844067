import classes from "./Landing.module.scss";
import {useRef, useEffect, useState, useContext, useCallback} from "react";
import ValidUserContext from "../authCheck";
import signoutIcon from "../assets/fa-logout.svg";
import pdfIcon from "../assets/akar-icons_pdf.svg";
import csvIcon from "../assets/akar-icons_csv.svg";
import pwrpIcon from "../assets/akar-icons_pwrp.svg";
import excelIcon from "../assets/akar-icons_excel.svg";

import moontideLogo from "../assets/moontide_logo.svg";
import pngIcon from "../assets/akar-icons_png.svg";
import dividerIcon from "../assets/akar-icons_divider.svg";
import Dashboard from "./Dashboard"; 
import transparent_logo from "../assets/everywhen_logo.svg";

import Navigation from "./Navigation";


const Landing = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeButton, setActiveButton] = useState(0);
    const [activeDashboard, setActiveDashboard] = useState(true);
    const [activeURL, setActiveURL] = useState("Home/Home");
    const [displayTabs, setDisplayTabs] = useState(false);
    const [displayToolbarButtons, setDisplayToolbarButtons] = useState(false);

    const [defultFolder, setDefaultFolder] = useState('Home');
    const [defultFolderId, setDefaultFolderId] = useState('Home');
    const [defaultUser, setDefaultUser] = useState(JSON.parse(localStorage.getItem("user-name")) ?? "");
    const [defaultGroup, setDefaultGroup] = useState(JSON.parse(localStorage.getItem("group")) ?? "everywhen");
    const [defaultCompany, setDefaultCompany] = useState((JSON.parse(localStorage.getItem("company_logo")) || JSON.parse(localStorage.getItem("company")))?? "everywhen");
    const [defaultClient, setDefaultClient] = useState((JSON.parse(localStorage.getItem("client_logo")) || JSON.parse(localStorage.getItem("client"))) ?? "everywhen");

    const [breadCrumbs, setBreadCrumbs] = useState([{"id":"Home", "name":"Home"}]);
    const [currentButtons, setCurrentButtons] = useState(['Home', 'Explore']);
    const [currentLinks, setCurrentLinks] = useState(['', '']);

    //const [dashboardRef, setDashboardRef] = useState(undefined)
    const dashboardRef = useRef(null)

    const validUserContext = useContext(ValidUserContext);

    const handleDashboardClick = (link) => {
        setActiveDashboard(true)
        setActiveURL(link)
        setDisplayTabs(true)
        setDisplayToolbarButtons(true)
    }

    const handleFolderClick = (name, id) => {
      setDefaultFolder(name)
      setDefaultFolderId(id)
      breadCrumbs.push({"id":id, "name":name})
      setBreadCrumbs(breadCrumbs)
    }

    const handleLogoutClick = () => {
        validUserContext.logoutUser()
    }
  
    const handleExportPDFClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-pdf");
    }

    const handleExportCSVClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-csv");
    }

    const handleExportPNGClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].exportImageAsync();
    }

    const handleExportPWRPClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-powerpoint");
    }

    const handleCrossTabClick = () => {
      dashboardRef.current.firstChild.firstChild.childNodes[1].displayDialogAsync("export-cross-tab");
    }


    const handleLogoClick = () => {
      setActiveDashboard(false)
    }

    const handleButtonClick = (tabIndex) => {
      validUserContext.localAuthCheck();
      setActiveButton(tabIndex);
      if (tabIndex == 0) {
        setActiveURL("Home/Home")
        setActiveDashboard(true)
        setDefaultFolder('Home')
        setDisplayTabs(false)
        setDisplayToolbarButtons(false)
        let newBreadCrumbs
        // Remove elements after the specific element
        newBreadCrumbs = breadCrumbs.slice(0, 1);
        setBreadCrumbs(newBreadCrumbs)
      } else {
        setActiveDashboard(false)
      }
    };

    const handleBreadCrumbClick = (folderName) => {
      setActiveDashboard(false)
      setDefaultFolder(folderName.name)
      setDefaultFolderId(folderName.id)

      let indexToRemove = breadCrumbs.indexOf(folderName);
      let newBreadCrumbs
      // Remove elements after the specific element
      if (indexToRemove !== -1) {
        newBreadCrumbs = breadCrumbs.slice(0, indexToRemove + 1);
      }
      setBreadCrumbs(newBreadCrumbs)
    }

    const renderContent = () => {
      if (activeDashboard)
          return renderDashboard()
      else 
          return renderNavigation()
    } 

    const renderNavigation = () => {
      return (
        <div>
            <Navigation defaultFolder={defultFolder} defaultFolderId={defultFolderId} onDashboardClick={handleDashboardClick} onFolderClick={handleFolderClick}></Navigation>
        </div>
      )
    }


    const renderDashboard = () => {
      return (
        <div>
            <Dashboard dashboardLink={activeURL} displayTabs={displayTabs}></Dashboard>
        </div>
      )
    };
    
    const rendeToolbar = () => {  
      
    }
    
    
    const renderButtons = () => {  
      return currentButtons.map((buttonText, index) => {
        if (activeButton === index) {
            return (<div className={`${classes.sideButton} ${classes.active}`} onClick={() => handleButtonClick(index)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        } else {
            return (<div className={`${classes.sideButton}`} onClick={() => handleButtonClick(index)}>{buttonText.replace(/^\d+\.\s*/, '')}</div>)
        }
      });
    };
    
    // var jwtToken = JSON.parse(localStorage.getItem("tableau-login-data"));
    // localStorage.setItem("tableau-login-data", JSON.stringify("redeemed"));

    // var inputProps = {
    // };
    
    // if (jwtToken != "redeemed") {
    //   inputProps.token = jwtToken;
    // }
    var dashboardURL = JSON.parse(localStorage.getItem("dashboard-url"));
    dashboardURL = dashboardURL + "ACA-Travel/TravelMediaPerformance" + '?:showVizHome=no&:embed=true&:toolbar=no&:tabs=n'
    var groupLink = 'https://storage.googleapis.com/evertide-portal-artifacts/'+defaultClient.toLowerCase()+'.png'
    var companyLink = 'https://storage.googleapis.com/evertide-portal-artifacts/'+defaultCompany.toLowerCase()+'.png'

    return (
      <div className={`${classes.landing}`}>
        <div className={`${classes.sidebar}`}>
          <div className={`${classes.sidebarlogo}`}/>
          <img className={classes.sidebarlogo} src={groupLink}></img>
          <div className={`${classes.sideState}`}>{defaultUser}</div>
          <div className={`${classes.sideHeader}`}>Navigation</div>
          {renderButtons()}
          <div className={`${classes.sidePortalName}`}>
          <img className={classes.sidebarlogo} src={companyLink}></img>

          </div>
        </div>
        <div className={`${classes.contentblock}`}>
          <div className={`${classes.toolbar}`}>
              <img
                    className={classes.icon}
                    src={signoutIcon}
                    alt="Signout icon"
                    htmlFor="sign-out"
                    onClick={() => handleLogoutClick()}
              ></img>
              <span className={`${classes.logout}`} onClick={() => handleLogoutClick()}>Logout</span>
              {displayToolbarButtons? 
              (
                <div>
                    <img
                    className={classes.dividericon}
                    src={dividerIcon}
                    alt="Divider icon"
                    htmlFor="divider"
                    ></img>
                    <img
                    className={classes.pdficon}
                    src={pdfIcon}
                    alt="PDF icon"
                    htmlFor="download-pdf"
                    onClick={() => handleExportPDFClick()}
                    ></img>
                    <img
                    className={classes.pdficon}
                    src={pngIcon}
                    alt="PNG icon"
                    htmlFor="download-png"
                    onClick={() => handleExportPNGClick()}
                    ></img>
                    <img
                    className={classes.pwrpicon}
                    src={pwrpIcon}
                    alt="Power Point icon"
                    htmlFor="download-pwrp"
                    onClick={() => handleExportPWRPClick()}
                    ></img>
                    <img
                    className={classes.excelicon}
                    src={excelIcon}
                    alt="Excel icon"
                    htmlFor="download-excel"
                    onClick={() => handleCrossTabClick()}
                    ></img>
                </div>
              ):(
                <div></div>
              )
              }
              
              {breadCrumbs.map((folderName) => (
                <div className={`${classes.breadcrumb}`} onClick={() => handleBreadCrumbClick(folderName)}>{folderName.name} &gt;</div>
              ))}
          </div>
          <div className={classes.dashboardblock} ref={dashboardRef} >
              {renderContent()}
          </div>
        </div>
        
      </div>
    );
  };
  
  export default Landing;
